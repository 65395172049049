import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form, Input, Button, Spin, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { serverTokenUrl, serverUrl } from "../../constants/config";
import { timeStamp } from "../../utility/timestamp";
import { useAuth } from "../../lib/auth";
import AspectRatio from "react-aspect-ratio";
import Axios from "axios";
import db from "../../database";
import loginBackground from "../../assets/images/backgroundImage.png";
import logoIcon from "../../assets/images/pos-logo-bordered.png";
import "react-aspect-ratio/aspect-ratio.css";
import "antd/dist/antd.css";
import "../style.css";

const TillRegistration = () => {
  const [loading, setLoading] = useState({ status: false, message: "" });
  const history = useHistory();
  const { setAuthTokens } = useAuth();
  const qs = require("querystring");

  useEffect(() => {
    const tillRegistrationData = async () => {
      const tillRegistrations = await db.tillRegistrations.toArray();
      if (tillRegistrations.length > 0) history.push("/");
    };
    tillRegistrationData();
  }, [history]);

  const onRegister = (values) => {
    setLoading({ status: true, message: "" });
    console.log("Login:", values);
    const username = values.username.trim();
    const password = values.password.trim();
    const tillId = values.tillId.trim();

    const requestBody = {
      grant_type: "password",
      username,
      password,
    };
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=",
      },
    };
    Axios.post(serverTokenUrl, qs.stringify(requestBody), config)
      .then((resTokenData) => {
        const access_token = resTokenData.data.access_token;
        const token_type = resTokenData.data.token_type;
        setAuthTokens(resTokenData.data);
        const timeMark = timeStamp();
        const paramsInput = {
          query: `mutation {
              tillRegistration(
                till: {
                  searchKey: "${tillId}"
                  created: "${timeMark}"
                  updated: "${timeMark}"
                  tillAccess: { csUser: { username: "${username}" } }
                  tillHistory: {
                    domain: null
                    sessionid: null
                    osVersion: null
                    localIpAddress: null
                    macAddress: null
                    processor: null
                    ram: null
                    regTimestamp: "${timeMark}"
                  }
                }
              ) {
                status
                message
                searchKey
                tillAccess {
                  csClientId
                  csUser {
                    csUserId
                  }
                  cwrTill {
                    till
                    searchKey
                    description
                    nextAssignedNumber
                  }
                  csBunit {
                    csBunitId
                    name
                    cwrSCustomerId
                    csCurrency {
                      csCurrencyId
                      currSymbol
                      isoCode
                      stdPrecision
                      cstgPrecision
                      prcPrecision
                    }
                  }
                }
                tillHistory {
                  domain
                  sessionid
                  osVersion
                  localIpAddress
                  macAddress
                  processor
                  ram
                  regTimestamp
                }
              }
            }`,
        };
        Axios({
          url: serverUrl,
          method: "POST",
          data: paramsInput,
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${token_type} ${access_token}`,
          },
        })
          .then((resData) => {
            const tillData = resData.data.data.tillRegistration;
            if (tillData.status === "200") {
              db.tillRegistrations
                .add(tillData)
                .then(() => {
                  setLoading({ status: false, message: "" });
                  localStorage.setItem("documentSequence", tillData.tillAccess[0].cwrTill.nextAssignedNumber);
                  history.push("/");
                })
                .catch((error) => {
                  setLoading({ status: false, message: "" });
                  errorMessage("Till Registration Failed", "Unexpected Error");
                  console.log(error);
                });
            } else {
              setLoading({ status: false, message: "" });
              errorMessage(tillData.message);
              console.log(tillData);
            }
          })
          .catch((error) => {
            setLoading({ status: false, message: "" });
            errorMessage("Till Registration Failed", "Retry or Check Till Access");
            console.log(error);
          });
      })
      .catch((error) => {
        setLoading({ status: false, message: "" });
        errorMessage("Till Registration Failed", "Invalid Username or Password");
        console.log(error);
      });
  };

  const onRegisterInvalid = (error) => {
    console.log("Till registration failed:", error);
  };

  const errorMessage = (modalTitle, modalContent) => {
    Modal.error({
      title: modalTitle,
      content: modalContent,
    });
  };

  const antIcon = <LoadingOutlined className="spinLoader" spin />;

  const sectionStyle = {
    width: "100%",
    backgroundImage: `url(${loginBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: "100%",
  };

  return (
    <AspectRatio ratio="19/6">
      <div style={sectionStyle}>
        <Card className="login-card">
          <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
            <img className="loginLogo" src={logoIcon} alt="" />
            <Form onFinish={onRegister} onFinishFailed={onRegisterInvalid}>
              <Form.Item name="tillId" rules={[{ required: true, message: "Please input your Till ID" }]}>
                <Row>
                  <Col span={8}>
                    <label className="login-label">Till ID</label>
                  </Col>
                  <Col span={16}>
                    <Input className="login-input" placeholder="Till ID" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="username" rules={[{ required: true, message: "Please input your Username" }]}>
                <Row gutter="5">
                  <Col span={8}>
                    <label>Username</label>
                  </Col>
                  <Col span={16}>
                    <Input className="login-input" placeholder="Username" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: "Please input your Password" }]}>
                <Row gutter="5">
                  <Col span={8}>
                    <label>Password</label>
                  </Col>
                  <Col span={16}>
                    <Input.Password className="login-input" placeholder="Password" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <br />
                <Row gutter={[5, 0]}>
                  <Col span={12} />
                  <Col span={12}>
                    <Button size="large" className="btnCommon" htmlType="submit">
                      Register
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <br />
        <div className="copyright-main-div">
          <label className="copyright">&emsp;&emsp;Copyright © {new Date().getFullYear()} CW Solutions Pvt Ltd | All rights reserved</label>
          <br />
          <label className="copyright">&emsp;&emsp;www.cw.solutions</label>
        </div>
      </div>
    </AspectRatio>
  );
};

export default TillRegistration;
