import Dexie from "dexie";

const db = new Dexie("cwpos");
db.version(1)
  .stores({
    tillRegistrations: "++id",
    productCategories: "mProductCategoryId",
    products: "++id, mProductId, mProductCategoryId, value, name, upc, *batchIndex, *upcIndex",
    orders: "sOrderID, orderTime, documentno, isSynced, tillSessionId",
    pricingRules: "++id, billAmount, mPricingrulesId",
    posSaletypes: "++id, cwrSaletypeId",
    productUom: "++id, csUomId",
    tillEvents: "++id, tillSessionId, isSynced",
  })
  .upgrade((tx) => {
    // Database Migrations
    console.log("DB Migration -> ", tx);
  });

export default db;
