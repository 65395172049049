import React, { useState } from "react";
import { Card, Row, Col, Input } from "antd";
import Quantity from "../../assets/images/scale.png";
import Sales from "../../assets/images/sales.png";
import Offers from "../../assets/images/offers.png";
import Stock from "../../assets/images/stock-white.png";
import Info from "../../assets/images/Info.png";
import Aclose from "../../assets/images/close.png";
import ok from "../../assets/images/tick.png";
import Backspace from "../../assets/images/backspac.png";
import DefaultProductImage from "../../assets/images/default-product.webp";
import "../style.css";

const QuantityUpdate = (props) => {
  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const [weight, setWeight] = useState("");

  const onChangeWeight = (event) => {
    setWeight(event.target.value);
  };

  const handleWeight = (value) => {
    if (weight === "" && value === "x") {
      setWeight("");
    } else if (value === "x") {
      setWeight(`${weight.toString().substring(0, weight.toString().length - 1)}`);
    } else {
      setWeight(`${weight}${value}`);
    }
  };

  const addQuantity = () => {
    const validation = RegExp("^[0-9]+(.[0-9]{1,2})?$");
    if (validation.test(parseFloat(weight))) {
      const netWeight = parseFloat(weight) - parseFloat(props.selectedProductInCart.weight);
      props.addProduct(props.selectedProductInCart, netWeight);
      props.setIsQtyUpdate(false);
    } else {
      console.warn("Invalid input", weight);
    }
  };

  const full = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
  };

  const four = {
    xs: { span: 4 },
    sm: { span: 4 },
    md: { span: 4 },
    lg: { span: 4 },
    xl: { span: 4 },
    xxl: { span: 4 },
  };

  const half = {
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
    xxl: { span: 12 },
  };

  const twenty = {
    xs: { span: 20 },
    sm: { span: 20 },
    md: { span: 20 },
    lg: { span: 20 },
    xl: { span: 20 },
    xxl: { span: 20 },
  };

  return (
    <div>
      <Row gutter={[12, 0]}>
        <Col {...full}>
          <Row gutter={[0, 10]}>
            <Col {...full}>
              <Card
                bodyStyle={{
                  padding: 10,
                  height: "18vh",
                  borderRadius: "6px",
                }}
              >
                <Row>
                  <Col {...four} style={{ padding: "20px" }}>
                    <img className="wfifty" onError={setDefaultImage} src={props.selectedProductInCart.imageurl} alt="" />
                  </Col>
                  <Col {...twenty}>
                    <Row>
                      <Col {...full}>
                        <Row>
                          <label style={{ paddingTop: 5 }}>
                            <span className="prod-name-details">{props.selectedProductInCart.name}</span>
                          </label>
                        </Row>
                        <Row>
                          <label className="prod-price-lbl" style={{ paddingTop: 10 }}>
                            &#8377;&nbsp;
                            <span style={{ fontWeight: "bold" }}>{props.selectedProductInCart.salePrice}</span>
                          </label>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <p style={{ fontSize: "12px", paddingTop: "10px", marginBottom: 0 }}>{props.selectedProductInCart.description}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[10, 0]}>
        <Col span={14}>
          <Card bodyStyle={{ height: "57vh", padding: 8, paddingTop: 12 }}>
            <Row gutter={[0, 0]}>
              <Col span={24}>
                <Input size="large" value={weight} onChange={onChangeWeight} allowClear={false} className="quantityUpdateInput" />
              </Col>
            </Row>
            <div>
              <Row style={{ paddingTop: 8 }}>
                <Col span={6} className="col-text-align">
                  <button className="quantity-btn" onClick={() => handleWeight("1")}>
                    1
                  </button>
                </Col>
                <Col span={6} className="col-text-align">
                  <button className="quantity-btn" onClick={() => handleWeight("2")}>
                    2
                  </button>
                </Col>
                <Col span={6} className="col-text-align">
                  <button className="quantity-btn" onClick={() => handleWeight("3")}>
                    3
                  </button>
                </Col>
                <Col span={6} className="col-text-align">
                  <button className="quantity-btn" onClick={() => props.setIsQtyUpdate(false)}>
                    <img src={Aclose} width={25} height={25} alt="close icon" />
                  </button>
                </Col>
              </Row>
              <Row style={{ paddingTop: 10 }}>
                <Col span={6} className="col-text-align">
                  <button className="quantity-btn" onClick={() => handleWeight("4")}>
                    4
                  </button>
                </Col>
                <Col span={6} className="col-text-align">
                  <button className="quantity-btn" onClick={() => handleWeight("5")}>
                    5
                  </button>
                </Col>
                <Col span={6} className="col-text-align" onClick={() => handleWeight("6")}>
                  <button className="quantity-btn">6</button>
                </Col>
                <Col span={6} className="col-text-align">
                  <button className="quantity-btn" onClick={() => handleWeight("x")}>
                    <img src={Backspace} alt="back space" width={30} height={25} />
                  </button>
                </Col>
              </Row>
              <Row style={{ paddingTop: 10 }}>
                <Col span={18}>
                  <Row>
                    <Col span={8} className="col-text-align">
                      <button className="quantity-btn" onClick={() => handleWeight("7")}>
                        7
                      </button>
                    </Col>
                    <Col span={8} className="col-text-align">
                      <button className="quantity-btn" onClick={() => handleWeight("8")}>
                        8
                      </button>
                    </Col>
                    <Col span={8} className="col-text-align" onClick={() => handleWeight("9")}>
                      <button className="quantity-btn">9</button>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 15 }}>
                    <Col span={16}>
                      <button className="quantity-btn-zero" onClick={() => handleWeight("0")}>
                        0
                      </button>
                    </Col>
                    <Col span={8} className="col-text-align">
                      <button className="quantity-btn" onClick={() => handleWeight(".")}>
                        .
                      </button>
                    </Col>
                  </Row>
                </Col>
                <Col span={6} className="col-text-align">
                  <button className="quantity-btn-ok" onClick={addQuantity}>
                    <img src={ok} alt="ok" width={40} height={30} />
                  </button>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col span={10}>
          <Card className="selected txt-cen qty-card" bodyStyle={{ padding: 10 }}>
            <Row style={{ paddingTop: 15 }}>
              <Col {...half}>
                <img src={Quantity} alt="" className="qty-img" />
              </Col>
              <Col {...half} className="padding-ten">
                <label className="qty-lbl">Quantity</label>
              </Col>
            </Row>
          </Card>
          <Card className="txt-cen qty-card no-border-crd" bodyStyle={{ padding: 10 }}>
            <Row style={{ paddingTop: 15 }}>
              <Col {...half}>
                <img src={Offers} alt="" className="qty-img" />
              </Col>
              <Col {...half} className="padding-ten">
                <label className="qty-lbl">Offers</label>
              </Col>
            </Row>
          </Card>
          <Card className="txt-cen qty-card no-border-crd" bodyStyle={{ padding: 10 }}>
            <Row style={{ paddingTop: 15 }}>
              <Col {...half}>
                <img src={Stock} alt="" className="qty-img" />
              </Col>
              <Col {...half} className="padding-ten">
                <label className="qty-lbl">Stock</label>
              </Col>
            </Row>
          </Card>
          <Card className="txt-cen qty-card no-border-crd" bodyStyle={{ padding: 10 }}>
            <Row style={{ paddingTop: 15 }}>
              <Col {...half}>
                <img src={Sales} alt="" className="qty-img" />
              </Col>
              <Col {...half} className="padding-ten">
                <label className="qty-lbl">Sales</label>
              </Col>
            </Row>
          </Card>
          <Card className="txt-cen qty-card no-border-crd" bodyStyle={{ padding: 10 }}>
            <Row style={{ paddingTop: 15 }}>
              <Col {...half}>
                <img src={Info} alt="" className="qty-img" />
              </Col>
              <Col {...half} className="padding-ten">
                <label className="qty-lbl">Info</label>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default QuantityUpdate;
