import React, { useState } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { AuthContext } from "../lib/auth";
import PrivateRoute from "./PrivateRoute";
import Login from "./Login";
import TillReg from "./TillRegistration";
import PointOfSale from "./PointOfSale";
import Dashboard from "./Dashboard";
import Sync from "./Sync";
import OpenTill from "./OpenTill";
import CloseTill from "./CloseTill";
import CloseTillAll from "./CloseTill/CloseTIllAll";

const App = (props) => {
  const [authTokens, setAuthTokens] = useState(localStorage.getItem("tokens"));
  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  };

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router>
        <Route exact path="/" component={Login} />
        <Route exact path="/registration" component={TillReg} />
        <PrivateRoute exact path="/pos" component={PointOfSale} />
        <PrivateRoute exact path="/sync" component={Sync} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/open-till" component={OpenTill} />
        <PrivateRoute exact path="/close-till" component={CloseTill} />
        <PrivateRoute exact path="/close-till-all" component={CloseTillAll} />
        {/* <PrivateRoute exact path="/cash-event" component={CashEvent} /> */}
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
