export const tillClosingPrint = (printContent) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printWindow = window.open("", "printWindow", "width=0,height=0");
  let printData = tillData.tillAccess.cwrTill.tillCloseTemplate.htmlcode2;

  let openingCashDenominations = "";
  printContent.openingCashDenominations.map((data) => {
    return (openingCashDenominations += `<tr>
    <td>${data.denomination}</td>
    <td class="left">x</td>
    <td class="left">${data.count}</td>
    <td class="left">=</td>
    <td class="right">${parseFloat(data.amount).toFixed(2)}</td>
  </tr>`);
  });

  let expectedAmountsData = "";
  printContent.allPaymentsData.map((data) => {
    return (expectedAmountsData += `<tr>
    <td>${data.name}</td>
    <td class="right">${parseFloat(data.expectedAmount).toFixed(2)}</td>
  </tr>`);
  });

  let differenceAmountsData = "";
  let differenceAmountsDataTotal = 0;
  printContent.allPaymentsData.map((data) => {
    const amt = parseFloat(data.difference).toFixed(2);
    differenceAmountsDataTotal = parseFloat(differenceAmountsDataTotal) + parseFloat(amt);
    return (differenceAmountsData += `<tr>
    <td>${data.name}</td>
    <td class="right">${amt}</td>
  </tr>`);
  });

  let countedAmountsData = "";
  printContent.allPaymentsData.map((data) => {
    if (data.name.toLowerCase() !== "cash") {
      return (countedAmountsData += `<tr>
    <td colspan="4">${data.name}</td>
    <td class="right">${parseFloat(data.amount).toFixed(2)}</td>
  </tr>`);
    } else {
      return null;
    }
  });

  let cashClosingDenominations = "";
  printContent.closingCashDenominations.map((data) => {
    return (cashClosingDenominations += `<tr>
    <td>${data.denomination}</td>
    <td class="left">x</td>
    <td class="left">${data.count}</td>
    <td class="left">=</td>
    <td class="right">${parseFloat(data.amount).toFixed(2)}</td>
  </tr>`);
  });

  let closingCash = `<tr>
  <td colspan="4">Cash</td>
  <td class="right">${parseFloat(printContent.closingCash).toFixed(2)}</td>
</tr>`;

  const additionalBodyData = `<script>
       setTimeout(() => {
         window.print();
         window.close();
       }, 1000);
       </script>`;

  // tempPrintData start
  /* let printData = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Receipt</title>
    <style>
      * {
        font-size: 12px;
        font-family: "Times New Roman";
      }
      table {
        width: 100%;
      }
      .centered {
        text-align: center;
      }
      .left {
        text-align: left;
      }
      .right {
        text-align: right;
      }
      .center {
        margin-left: auto;
        margin-right: auto;
      }
      .logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 50px;
        width: 120px;
      }
      .line-spacing {
        margin-top: 2px;
        margin-bottom: 2px;
      }
      .horizontal-line {
        border-top: 1px solid #000000;
      }
    </style>
  </head>
  <body style="padding: 2px">
    <img alt="CW ERP" class="logo" src="https://cw.solutions/wp-content/uploads/2020/02/CW_LOGO_90.jpg" />
    <br />
    <p class="centered">@@csBunitName@@ <br />@@csBunitCustomerAddressFulladdress@@ <br />@@csBunitCustomerAddressCity@@ <br />Phone: @@csBunitCustomerAddressPhone@@</p>
    <hr class="horizontal-line" />
    <p class="centered">
      <b>Day Close Report</b><br />
      Terminal: @@terminal@@<br />
      User: @@user@@
    </p>
    <table>
      <tr>
        <td>Opening Time:</td>
        <td class="right">@@openingTime@@</td>
      </tr>
      <tr>
        <td>Closing Time:</td>
        <td class="right">@@closingTime@@</td>
      </tr>
    </table>
    <hr class="horizontal-line" />
    <table>
      <tr>
        <td><b>Gross Sales</b></td>
        <td class="right"><b>@@grossSales@@</b></td>
      </tr>
      <tr>
        <td>Net Sales</td>
        <td class="right">@@netSales@@</td>
      </tr>
    </table>
    <hr class="horizontal-line" />
    <table>
      <tr>
        <td><b>TOTAL RETAIL TRANSACTIONS</b></td>
        <td class="right"><b>@@totalRetailTransactions@@</b></td>
      </tr>
    </table>
    <hr class="horizontal-line" />
    <table>
      <tr>
        <td><b>Opening Balance</b></td>
        <td class="right"><b>@@openingBalance@@</b></td>
      </tr>
      <tr>
        <td>Total Opening</td>
        <td class="right">@@totalOpening@@</td>
      </tr>
    </table>
    <hr class="horizontal-line" />
    <table>
      @@openingCashDenominations@@
    </table>
    <hr class="horizontal-line" />
    <table>
      @@expectedAmountsData@@
      <tr>
        <td><b>Expected Amount</b></td>
        <td class="right"><b>@@expectedAmount@@</b></td>
      </tr>
    </table>
    <hr class="horizontal-line" />
    <table>
      @@differenceAmountsData@@
      <tr>
        <td><b>Difference Amount</b></td>
        <td class="right"><b>@@differenceAmount@@</b></td>
      </tr>
    </table>
    <hr class="horizontal-line" />
    <table>
      @@cashAmountCounted@@ @@cashClosingDenominations@@ @@countedAmountsData@@
      <tr>
        <td colspan="4"><b>Counted Amount</b></td>
        <td class="right"><b>@@countedAmount@@</b></td>
      </tr>
    </table>
    <hr class="horizontal-line" />
    <table>
      <tr>
        <td><b>Total to Deposit</b></td>
        <td class="right"><b>@@totalToDeposit@@</b></td>
      </tr>
      <tr>
        <td><b>Total Transaction Count</b></td>
        <td class="right"><b>@@totalTransactionCount@@</b></td>
      </tr>
      <tr>
        <td><b>Avg. Billing Value</b></td>
        <td class="right"><b>@@avgBillingValue@@</b></td>
      </tr>
    </table>
    @@additionalBodyData@@
  </body>
</html>`; */
  // tempPrintData end

  printData = printData.replace("@@csBunitName@@", `${tillData.tillAccess.csBunit.name}`);
  printData = printData.replace("@@csBunitCustomerAddressFulladdress@@", `${tillData.tillAccess.csBunit.customerAddress.fulladdress}`);
  printData = printData.replace("@@csBunitCustomerAddressCity@@", `${tillData.tillAccess.csBunit.customerAddress.city}`);
  printData = printData.replace("@@csBunitCustomerAddressPhone@@", `${tillData.tillAccess.csBunit.customerAddress.phone}`);
  printData = printData.replace("@@terminal@@", `${tillData.tillAccess.cwrTill.till}`);
  printData = printData.replace("@@user@@", `${localStorage.getItem("username")}`);
  printData = printData.replace("@@openingTime@@", `${printContent.openingTime}`);
  printData = printData.replace("@@closingTime@@", `${printContent.closingTime}`);
  printData = printData.replace("@@grossSales@@", `${parseFloat(printContent.totalClosingExpectedAmount).toFixed(2)}`);
  printData = printData.replace("@@netSales@@", `${parseFloat(printContent.totalClosingExpectedAmount).toFixed(2)}`);
  printData = printData.replace("@@totalRetailTransactions@@", `${printContent.totalOrdersCount}`);
  printData = printData.replace("@@openingBalance@@", `${parseFloat(printContent.openingCash).toFixed(2)}`);
  printData = printData.replace("@@totalOpening@@", `${parseFloat(printContent.openingCash).toFixed(2)}`);
  printData = printData.replace("@@openingCashDenominations@@", `${openingCashDenominations}`);
  printData = printData.replace("@@expectedAmountsData@@", `${expectedAmountsData}`);
  printData = printData.replace("@@expectedAmount@@", `${parseFloat(printContent.totalClosingExpectedAmount).toFixed(2)}`);
  printData = printData.replace("@@differenceAmountsData@@", `${differenceAmountsData}`);
  printData = printData.replace("@@differenceAmount@@", `${parseFloat(differenceAmountsDataTotal).toFixed(2)}`);
  printData = printData.replace("@@cashAmountCounted@@", `${closingCash}`);
  printData = printData.replace("@@cashClosingDenominations@@", `${cashClosingDenominations}`);
  printData = printData.replace("@@countedAmountsData@@", `${countedAmountsData}`);
  printData = printData.replace("@@countedAmount@@", `${parseFloat(printContent.totalClosingAmount).toFixed(2)}`);
  printData = printData.replace("@@totalToDeposit@@", `${parseFloat(printContent.totalClosingAmount).toFixed(2)}`);
  printData = printData.replace("@@totalTransactionCount@@", `${printContent.totalClosingTransactions}`);
  printData = printData.replace("@@avgBillingValue@@", `${parseFloat(printContent.totalClosingAmount / printContent.totalOrdersCount).toFixed(2)}`);
  printData = printData.replace("@@additionalBodyData@@", `${additionalBodyData}`);

  printWindow.document.write(printData);
};
