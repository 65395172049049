import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Card, Input, Divider, Button } from "antd";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { clock } from "../../utility/clock";
import { timeStamp } from "../../utility/timestamp";
import { v4 as uuidv4 } from "uuid";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import logoIcon from "../../assets/images/pos-logo-bordered.png";
import db from "../../database";
import "../style.css";

const OpenTill = () => {
  const history = useHistory();

  const [currencyType, setCurrencyType] = useState({ currSymbolLeft: "₹", currSymbolRight: "", stdPrecision: 2 });

  useEffect(() => {
    const tillSession = localStorage.getItem("tillSession");
    console.log(tillSession);
    if (tillSession) {
      if (JSON.parse(tillSession).tillStatus === "open") history.push("/pos");
    } else {
      db.tillRegistrations.toArray().then((reg) => {
        if (reg.length > 0) {
          const curList = reg[0].tillAccess[0].csBunit.csCurrency;
          const csCurrencyId = curList.csCurrencyId;
          localStorage.setItem("csCurrencyId", csCurrencyId);
          setCurrencyType({
            currSymbolLeft: curList.symbolrightside ? "" : curList.currSymbol,
            currSymbolRight: curList.symbolrightside ? curList.currSymbol : "",
            stdPrecision: curList.stdPrecision,
          });
        }
      });
    }
  }, [history]);

  const [denominations, setDenominations] = useState([
    {
      denomination: 1,
      count: 0,
      amount: 0,
    },
    {
      denomination: 2,
      count: 0,
      amount: 0,
    },
    {
      denomination: 5,
      count: 0,
      amount: 0,
    },
    {
      denomination: 10,
      count: 0,
      amount: 0,
    },
    {
      denomination: 20,
      count: 0,
      amount: 0,
    },
    {
      denomination: 50,
      count: 0,
      amount: 0,
    },
    {
      denomination: 100,
      count: 0,
      amount: 0,
    },
    {
      denomination: 200,
      count: 0,
      amount: 0,
    },
    {
      denomination: 500,
      count: 0,
      amount: 0,
    },
    {
      denomination: 2000,
      count: 0,
      amount: 0,
    },
  ]);

  const currentDate = new Date().toLocaleDateString();

  const [displayClock, setDisplayClock] = useState("");
  useEffect(() => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const [totalAmount, setTotalAmount] = useState(0);

  const onFieldInput = (e) => {
    const { name, value } = e.target;

    const denominationName = parseInt(name);
    const denominationValue = parseInt(value);
    const denominationAmount = denominationName * denominationValue;

    const di = denominations.findIndex((d) => d.denomination === denominationName);
    denominations[di].count = isNaN(denominationValue) ? "" : denominationValue;
    denominations[di].amount = isNaN(denominationAmount) ? 0 : denominationAmount;

    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    console.log("toatal", total);
    setTotalAmount(total);
    setDenominations([...denominations]);
  };

  const onFieldOut = (e) => {
    if (e.target.value === "") {
      const di = denominations.findIndex((d) => d.denomination === parseInt(e.target.name));
      denominations[di].count = 0;
      setDenominations([...denominations]);
    }
  };

  const onFieldButton = (field, action) => {
    if (action === "plus") denominations[field].count += 1;
    if (action === "minus" && denominations[field].count > 0) denominations[field].count -= 1;
    const denominationAmount = denominations[field].denomination * denominations[field].count;
    const denominationAmountValue = isNaN(denominationAmount) ? 0 : denominationAmount;
    denominations[field].amount = denominationAmountValue;
    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    console.log("toatal", total);
    setTotalAmount(total);
    setDenominations([...denominations]);
  };

  const confirmBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);

  const excuteOpenTill = () => {
    confirmBtnRef.current.disabled = true;
    cancelBtnRef.current.disabled = true;
    const tillSessionId = uuidv4().replace(/-/g, "").toUpperCase();

    const openTillData = {
      tillSessionId: tillSessionId,
      tillStatus: "open",
      openingTime: timeStamp(),
      closingTime: null,
      openingCashDenominations: denominations,
      closingCashDenominations: null,
      openingCash: totalAmount,
      closingCash: null,
      totalOpeningAmount: totalAmount,
      totalClosingExpectedAmount: null,
      totalClosingTransactions: null,
      totalClosingAmount: null,
    };

    localStorage.setItem("tillSession", JSON.stringify(openTillData));
    confirmBtnRef.current.disabled = false;
    cancelBtnRef.current.disabled = false;
    history.push("/pos");
  };

  return (
    <div className="main-container">
      <Card className="card-shadow" bodyStyle={{ padding: 8 }}>
        <Row>
          <Col>
            <img className="logo-icon" src={logoIcon} alt="" />
          </Col>
        </Row>
      </Card>
      <div className="main-card-container">
        <Row gutter={10}>
          <Col span={18}>
            <Card key="z" className="card-shadow main-card-first" bodyStyle={{ padding: 10 }}>
              <Row>
                <Col span={8} className="col-align">
                  <strong>Denomination</strong>
                </Col>
                <Col span={8} className="col-amonut">
                  <strong style={{ marginRight: "15%" }}>Count</strong>
                </Col>
                <Col span={8} className="col-amonut">
                  <strong style={{ marginRight: "5%" }}>Amount</strong>
                </Col>
              </Row>
              {denominations.map((denom, index) => (
                <Card key={`${index}`} className="card-shadow" style={{ marginBottom: "10px" }} bodyStyle={{ padding: 6 }}>
                  <Row>
                    <Col span={8} className="col-align">
                      <Input readOnly={true} className="currency" value={denom.denomination} />
                    </Col>
                    <Col span={8} className="col-align">
                      <Input className="currency-count" name={`${denom.denomination}`} value={denom.count} onChange={onFieldInput} onBlur={onFieldOut} prefix={<MinusOutlined type="minus" onClick={() => onFieldButton(index, "minus")} />} suffix={<PlusOutlined type="plus" onClick={() => onFieldButton(index, "plus")} />} />
                    </Col>
                    <Col span={8} className="col-align">
                      <Input style={{ width: "80%" }} className="total-input" readOnly={true} value={`${currencyType.currSymbolLeft} ${denom.amount} ${currencyType.currSymbolRight}`} />
                    </Col>
                  </Row>
                </Card>
              ))}
            </Card>
          </Col>
          <Col span={6}>
            <Link to="/dashboard">
              <Button ref={cancelBtnRef} className="btn-cancel">
                Cancel
              </Button>
            </Link>
            <Button ref={confirmBtnRef} className="btn-confirm" onClick={excuteOpenTill}>
              Confirm
            </Button>
            <p style={{ marginBottom: "0.5rem" }} />
            <Card className="card-shadow main-card-second-open-till">
              <strong>CW</strong>
              <Divider style={{ margin: "5px 0" }} />
              <span>{currentDate}</span>
              <span style={{ float: "right" }}>{displayClock}</span>
              <br />
              <Form>
                <Form.Item>
                  <strong>Total Amount</strong>
                  <Input value={`${currencyType.currSymbolLeft} ${parseFloat(totalAmount).toFixed(currencyType.stdPrecision)} ${currencyType.currSymbolRight}`} readOnly={true} />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OpenTill;
