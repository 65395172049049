module.exports = {
 //  server: "Pre Production",
 // serverTokenUrl: "https://s360app.mycw.in/cw-identity/oauth/token", // Pre Production Server


  // serverTokenUrl: "https://identity-demos.cw.solutions/cw-identity/oauth/token", // Demo Server
  // serverUrl: "https://s360app.mycw.in/cw-retail/graphql", // Pre Production Server
  // serverUrl: "https://s2demos.cw.solutions/cw-retail/graphql", // Demo Server
server: "test",
serverTokenUrl: "https://s20q1.mycw.in/cw-identity/oauth/token",
serverUrl: "https://s20q1.mycw.in/cw-retail/graphql",
  edcUrl: "http://localhost:80/innoweb/api/MSwipe",
  printerConfig: {
    ip: "192.168.0.101",
    port: "8008",
  },
  innovitiPaymentCodes: {
    Card: 0,
    GPay: 86,
    Sodexo: 90,
    UPI: 86,
  },
};
