import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Card, Input, Divider, Button } from "antd";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { clock } from "../../utility/clock";
import { timeStamp } from "../../utility/timestamp";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import logoIcon from "../../assets/images/pos-logo-bordered.png";
import db from "../../database";
import "../style.css";

const CloseTill = () => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const paymentMethodList = tillData.tillAccess.csBunit.paymentMethodList;
  const tillSession = JSON.parse(localStorage.getItem("tillSession"));
  const sessionId = tillSession.tillSessionId;
  const history = useHistory();

  const [currencyType, setCurrencyType] = useState({ currSymbolLeft: "₹", currSymbolRight: "", stdPrecision: 2 });
  const [cashExpectedAmount, setCashExpectedAmount] = useState(0);
  const [cashTransactionCount, setCashTransactionCount] = useState(0);
  const [allPaymentsData, setAllPaymentsData] = useState([]);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [totalOpeningAmount, setTotalOpeningAmount] = useState(0);
  const [cashSaleAmount, setCashSaleAmount] = useState(0);

  useEffect(() => {
    db.tillRegistrations.toArray().then((reg) => {
      if (reg.length > 0) {
        const curList = reg[0].tillAccess[0].csBunit.csCurrency;
        setCurrencyType({
          currSymbolLeft: curList.symbolrightside ? "" : curList.currSymbol,
          currSymbolRight: curList.symbolrightside ? curList.currSymbol : "",
          stdPrecision: curList.stdPrecision,
        });
      }
    });

    const newPaymentsList = [];
    db.orders
      .where("tillSessionId")
      .equals(sessionId)
      .toArray()
      .then((orders) => {
        paymentMethodList.forEach((payment) => {
          let currPaymentAmount = 0;
          let transactionCount = 0;
          orders.forEach((order) => {
            const orderPayments = order.payments;
            orderPayments.forEach((orderPayment) => {
              if (payment.name.toLowerCase() === orderPayment.name.toLowerCase()) {
                currPaymentAmount += parseFloat(orderPayment.amount);
                transactionCount += 1;
              }
            });
          });
          payment.expectedAmount = currPaymentAmount;
          payment.amount = 0;
          payment.difference = 0 - currPaymentAmount;
          payment.transactionCount = transactionCount;
          newPaymentsList.push(payment);
        });
        // console.log("Payment methods info:", newPaymentsList);
        setAllPaymentsData([...newPaymentsList]);
        const cashIndex = newPaymentsList.findIndex((p) => p.name.toLowerCase() === "cash");
        const cashAmount = parseFloat(newPaymentsList[cashIndex].expectedAmount);
        const cashTrxCount = parseFloat(newPaymentsList[cashIndex].transactionCount);
        const openAmount = JSON.parse(localStorage.getItem("tillSession")).totalOpeningAmount;
        setTotalOpeningAmount(openAmount);
        setTotalOrdersCount(orders.length);
        setCashSaleAmount(cashAmount);
        const expAmt = parseFloat(cashAmount) + parseFloat(openAmount);
        setCashExpectedAmount(expAmt);
        setCashTransactionCount(cashTrxCount);
      });
  }, []); // eslint-disable-line

  const [denominations, setDenominations] = useState([
    {
      denomination: 1,
      count: 0,
      amount: 0,
    },
    {
      denomination: 2,
      count: 0,
      amount: 0,
    },
    {
      denomination: 5,
      count: 0,
      amount: 0,
    },
    {
      denomination: 10,
      count: 0,
      amount: 0,
    },
    {
      denomination: 20,
      count: 0,
      amount: 0,
    },
    {
      denomination: 50,
      count: 0,
      amount: 0,
    },
    {
      denomination: 100,
      count: 0,
      amount: 0,
    },
    {
      denomination: 200,
      count: 0,
      amount: 0,
    },
    {
      denomination: 500,
      count: 0,
      amount: 0,
    },
    {
      denomination: 2000,
      count: 0,
      amount: 0,
    },
  ]);

  const currentDate = new Date().toLocaleDateString();

  const [displayClock, setDisplayClock] = useState("");
  useEffect(() => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const [totalAmount, setTotalAmount] = useState(0);

  const onFieldInput = (e) => {
    const { name, value } = e.target;

    const denominationName = parseInt(name);
    const denominationValue = parseInt(value);
    const denominationAmount = denominationName * denominationValue;

    const di = denominations.findIndex((d) => d.denomination === denominationName);
    denominations[di].count = isNaN(denominationValue) ? "" : denominationValue;
    denominations[di].amount = isNaN(denominationAmount) ? 0 : denominationAmount;

    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    // console.log("total", total);
    setTotalAmount(total);
    setDenominations([...denominations]);
  };

  const onFieldOut = (e) => {
    if (e.target.value === "") {
      const di = denominations.findIndex((d) => d.denomination === parseInt(e.target.name));
      denominations[di].count = 0;
      setDenominations([...denominations]);
    }
  };

  const onFieldButton = (field, action) => {
    if (action === "plus") denominations[field].count += 1;
    if (action === "minus" && denominations[field].count > 0) denominations[field].count -= 1;
    const denominationAmount = denominations[field].denomination * denominations[field].count;
    const denominationAmountValue = isNaN(denominationAmount) ? 0 : denominationAmount;
    denominations[field].amount = denominationAmountValue;
    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    // console.log("total", total);
    setTotalAmount(total);
    setDenominations([...denominations]);
  };

  const confirmBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);

  const excuteCloseTill = () => {
    confirmBtnRef.current.disabled = true;
    cancelBtnRef.current.disabled = true;
    const updatedTillSession = tillSession;

    const updatedAllPaymentsData = allPaymentsData;
    const aPi = updatedAllPaymentsData.findIndex((apd) => apd.name.toLowerCase() === "cash");
    updatedAllPaymentsData[aPi].expectedAmount = parseFloat(updatedAllPaymentsData[aPi].expectedAmount) + parseFloat(totalOpeningAmount);
    updatedAllPaymentsData[aPi].amount = totalAmount;
    updatedAllPaymentsData[aPi].cashSaleAmount = cashSaleAmount;
    updatedAllPaymentsData[aPi].difference = parseFloat(totalAmount) - parseFloat(updatedAllPaymentsData[aPi].expectedAmount);

    updatedTillSession.closingTime = timeStamp();
    updatedTillSession.closingCash = totalAmount;
    updatedTillSession.closingCashDenominations = denominations;
    updatedTillSession.allPaymentsData = updatedAllPaymentsData;
    updatedTillSession.totalOrdersCount = totalOrdersCount;

    localStorage.setItem("tillSession", JSON.stringify(updatedTillSession));
    confirmBtnRef.current.disabled = false;
    cancelBtnRef.current.disabled = false;
    history.push("/close-till-all");
  };

  return (
    <div className="main-container">
      <Card className="card-shadow" bodyStyle={{ padding: 8 }}>
        <Row>
          <Col>
            <img className="logo-icon" src={logoIcon} alt="" />
          </Col>
        </Row>
      </Card>
      <div className="main-card-container">
        <Row gutter={10}>
          <Col span={18}>
            <Card key="z" className="card-shadow main-card-first" bodyStyle={{ padding: 10 }}>
              <Row>
                <Col span={8} className="col-align">
                  <strong>Denomination</strong>
                </Col>
                <Col span={8} className="col-amonut">
                  <strong style={{ marginRight: "15%" }}>Count</strong>
                </Col>
                <Col span={8} className="col-amonut">
                  <strong style={{ marginRight: "5%" }}>Amount</strong>
                </Col>
              </Row>
              {denominations.map((denom, index) => (
                <Card key={`${index}`} className="card-shadow" style={{ marginBottom: "10px" }} bodyStyle={{ padding: 6 }}>
                  <Row>
                    <Col span={8} className="col-align">
                      <Input readOnly={true} className="currency" value={denom.denomination} />
                    </Col>
                    <Col span={8} className="col-align">
                      <Input className="currency-count" name={`${denom.denomination}`} value={denom.count} onChange={onFieldInput} onBlur={onFieldOut} prefix={<MinusOutlined type="minus" onClick={() => onFieldButton(index, "minus")} />} suffix={<PlusOutlined type="plus" onClick={() => onFieldButton(index, "plus")} />} />
                    </Col>
                    <Col span={8} className="col-align">
                      <Input style={{ width: "80%" }} className="total-input" readOnly={true} value={`${currencyType.currSymbolLeft} ${denom.amount} ${currencyType.currSymbolRight}`} />
                    </Col>
                  </Row>
                </Card>
              ))}
            </Card>
          </Col>
          <Col span={6}>
            <Link to="/dashboard">
              <Button ref={cancelBtnRef} className="btn-cancel">
                Back
              </Button>
            </Link>
            <Button ref={confirmBtnRef} className="btn-confirm" onClick={excuteCloseTill}>
              Next
            </Button>
            <p style={{ marginBottom: "0.5rem" }} />
            <Card className="card-shadow main-card-second-open-till">
              <strong>CW</strong>
              <Divider style={{ margin: "5px 0" }} />
              <span>{currentDate}</span>
              <span style={{ float: "right" }}>{displayClock}</span>
              <br />
              <br />
              <Form>
                <Form.Item>
                  <strong>Transactions :</strong> <span style={{ float: "right" }}>{cashTransactionCount}</span>
                </Form.Item>
                <Form.Item>
                  <strong>Opening Amount</strong>
                  <Input value={`${currencyType.currSymbolLeft} ${parseFloat(totalOpeningAmount).toFixed(currencyType.stdPrecision)} ${currencyType.currSymbolRight}`} readOnly={true} />
                </Form.Item>
                <Form.Item>
                  <strong>Cash Sale Amount</strong>
                  <Input value={`${currencyType.currSymbolLeft} ${parseFloat(cashSaleAmount).toFixed(currencyType.stdPrecision)} ${currencyType.currSymbolRight}`} readOnly={true} />
                </Form.Item>
                <Form.Item>
                  <strong>Expected Cash</strong>
                  <Input value={`${currencyType.currSymbolLeft} ${parseFloat(cashExpectedAmount).toFixed(currencyType.stdPrecision)} ${currencyType.currSymbolRight}`} readOnly={true} />
                </Form.Item>
                <Form.Item>
                  <strong>Actual Cash</strong>
                  <Input value={`${currencyType.currSymbolLeft} ${parseFloat(totalAmount).toFixed(currencyType.stdPrecision)} ${currencyType.currSymbolRight}`} readOnly={true} />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CloseTill;
