import React, { useEffect } from "react";
import { Row, Col, Card, message } from "antd";
import { useAuth } from "../../lib/auth";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../constants/config";
import { v4 as uuidv4 } from "uuid";
import POS from "../../assets/images/pos.svg";
import PurchaseGRN from "../../assets/images/purchasegrn.svg";
import Delivery from "../../assets/images/delivery.svg";
import CashMgmt from "../../assets/images/cashregister.svg";
import Reports from "../../assets/images/reports.svg";
import Stock from "../../assets/images/stock.svg";
import Settings from "../../assets/images/settings.svg";
import logoIcon from "../../assets/images/pos-logo.png";
import sync from "../../assets/images/sync.png";
import logoutIcon from "../../assets/images/logout.png";
import dashboardBG from "../../assets/images/dashboard_bg.png";
import Axios from "axios";
import db from "../../database";
import "../style.css";

const workTypeName = ["POS", "PurchaseGRN", "Delivery", "Cash Management", "Reports", "Stock", "Settings"];
const workTypeImg = [POS, PurchaseGRN, Delivery, CashMgmt, Reports, Stock, Settings];

const Dashboard = () => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const username = localStorage.getItem("username");

  const { setAuthTokens } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const nWStatus = navigator.onLine ? "online" : "offline";
    if (nWStatus === "online") {
      db.tillEvents
        .where("isSynced")
        .equals(0)
        .toArray()
        .then((events) => {
          if (events.length > 0) {
            for (let i = 0; i < events.length; i += 1) {
              const event = events[i];
              const tillCash = [];
              for (let j = 0; j < event.allPaymentsData.length; j += 1) {
                const paymentObj = event.allPaymentsData[j];

                if (paymentObj.name.toLowerCase() === "cash") {
                  const openedCashEvents = [];
                  const closedCashEvents = [];

                  for (let x = 0; x < event.openingCashDenominations.length; x += 1) {
                    openedCashEvents.push(`{
                      cwrCashEventdetailsID:"${uuidv4().replace(/-/g, "").toUpperCase()}"
                      count:${event.openingCashDenominations[x].count}
                      amount:${event.openingCashDenominations[x].amount}
                      denomination:${event.openingCashDenominations[x].denomination}
                    }`);
                  }

                  for (let z = 0; z < event.closingCashDenominations.length; z += 1) {
                    closedCashEvents.push(`{
                      cwrCashEventdetailsID:"${uuidv4().replace(/-/g, "").toUpperCase()}"
                      count:${event.closingCashDenominations[z].count}
                      amount:${event.closingCashDenominations[z].amount}
                      denomination:${event.closingCashDenominations[z].denomination}
                    }`);
                  }

                  tillCash.push(`{
                    cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                    date: "${event.closingTime}"
                    finPaymentmethodId: "${paymentObj.finPaymentmethodId}"
                    opening: ${event.openingCash}
                    sales: ${paymentObj.cashSaleAmount}
                    netsales: ${paymentObj.cashSaleAmount}
                    cashin: 0
                    cashout: 0
                    retainAmt: 0
                    closing: ${event.closingCash}
                    returns: 0
                    iscash: 1
                    isclose: 1
                    cashEvents: [
                      {
                        cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                        amount: ${event.openingCash}
                        expected: 0
                        diff: 0
                        transactionCount: 0
                        type: "Open"
                        description: ""
                        cashEventDetails: [${openedCashEvents}]
                      },
                      {
                        cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                        amount: ${event.closingCash}
                        expected: ${paymentObj.expectedAmount}
                        diff: ${paymentObj.difference}
                        transactionCount: ${paymentObj.transactionCount}
                        type: "Close"
                        description: ""
                        cashEventDetails: [${closedCashEvents}]
                      }
                    ]
                  }`);
                } else {
                  tillCash.push(`{
                    cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                    date: "${event.closingTime}"
                    finPaymentmethodId: "${paymentObj.finPaymentmethodId}"
                    opening: 0
                    sales: ${paymentObj.expectedAmount}
                    netsales: ${paymentObj.expectedAmount}
                    cashin: 0
                    cashout: 0
                    retainAmt: 0
                    closing: ${parseFloat(paymentObj.amount)}
                    returns: 0
                    iscash: 0
                    isclose: 1
                    cashEvents: [
                      {
                        cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                        amount: ${parseFloat(paymentObj.amount)}
                        expected: ${paymentObj.expectedAmount}
                        diff: ${paymentObj.difference}
                        transactionCount: ${paymentObj.transactionCount}
                        type: "Close"
                        description: ""
                        cashEventDetails: []
                      }
                    ]
                  }`);
                }
              }

              const paramsInput = {
                query: `mutation{
                  upsertTill(tillInfo:{
                    cwrTillID:"${tillData.tillAccess.cwrTill.cwrTillID}"
                    csbunitID:"${tillData.tillAccess.csBunit.csBunitId}"
                    csclientID:"${tillData.tillAccess.csClientId}"
                    created:"${event.openingTime}"
                    createdby:"${tillData.tillAccess.csUserId}"
                    updated:"${event.openingTime}"
                    updatedby:"${tillData.tillAccess.csUserId}"
                    tillCash:[${tillCash}]
                  })
                  {    
                    status
                    message
                    cwrTillID
                          tillCash{
                      
                      cwrTillCashId
                      date
                      finPaymentmethodId
                      opening
                      sales
                      netsales
                      cashin
                      cashout
                      retainAmt
                      closing
                      returns
                      iscash
                      isclose
                      cashEvents{
                        cwrCashEventsID
                        amount
                        expected
                        diff
                        transactionCount
                        type
                        description
                        cashEventDetails{
                          cwrCashEventdetailsID
                          count
                          amount
                          denomination
                        }
                      }
                    }
                  }
                }`,
              };
              Axios({
                url: serverUrl,
                method: "POST",
                data: paramsInput,
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${tokens.token_type} ${tokens.access_token}`,
                },
              }).then((response) => {
                const result = response.data.data.upsertTill;
                const status = result.status;
                if (status === "200") {
                  console.log(event.tillSessionId);
                  db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ isSynced: 1 });
                } else {
                  console.log("Failed Till Session Sync ====> ", response);
                  db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ isSynced: 2 });
                }
              });
            }
          }
        });
    }
  }, []); // eslint-disable-line

  const handleLogout = () => {
    setAuthTokens();
    localStorage.removeItem("tokens");
  };

  const syncData = () => {
    history.push("/sync");
  };

  const selectActivity = (activity) => {
    if (activity === "POS") {
      if (JSON.parse(tillData.tillAccess.cwrTill.accessController).pos === "Y") {
        history.push("/open-till");
      } else {
        message.error("Access for POS Denied");
      }
    }
    if (activity === "Cash Management") {
      const tillSession = localStorage.getItem("tillSession");
      if (tillSession) {
        if (JSON.parse(tillSession).tillStatus === "open") {
          const storedParkedList = JSON.parse(localStorage.getItem("parkedList"));
          const initialParkedList = storedParkedList ? storedParkedList : [];
          if (initialParkedList.length === 0) {
            history.push("/close-till");
          } else {
            message.warning("Please close parked bills before closing till");
          }
        } else {
          history.push("/open-till");
        }
      }
    }
  };

  const rows = [];
  let cols = [];
  const workTypeImgLength = workTypeImg.length;
  for (let j = 0; j < workTypeImgLength; j += 1) {
    cols.push(
      <Col key={`${workTypeName}${j}`} span={4}>
        <Card bodyStyle={{ height: "25vh", padding: "6vh" }} className="cardWorkType" onClick={() => selectActivity(workTypeName[j])}>
          <img className="imgWorkType" src={workTypeImg[j]} alt="" />
          <br />
          <label className="dashboard-label"></label>
          {workTypeName[j]}
        </Card>
      </Col>
    );
    if (cols.length === 6 || j === workTypeImgLength - 1) {
      rows.push(
        <Row key={`${workTypeName}${j + 1}`} gutter={12} style={j % 6 === 0 ? { marginTop: "2vh" } : { marginTop: "0vh" }}>
          {cols}
        </Row>
      );
      cols = [];
    }
  }

  const sectionStyle = {
    width: "100%",
    backgroundImage: `url(${dashboardBG})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: "100%",
  };

  return (
    <div style={sectionStyle}>
      <Row className="dashboard-first-row">
        <Col span={3}>
          <img className="logoIcon" src={logoIcon} alt="" />
        </Col>
        <Col span={13} className="dashboard-info">
          {tillData.tillAccess.csBunit.name}
          <br />
          {tillData.tillAccess.cwrTill.till}
        </Col>
        <Col span={8} className="dashboard-greet">
          Hi, {username} &emsp;
        </Col>
      </Row>
      <div className="workType">{rows}</div>
      <Row className="dashboard-sync-logout-mainrow">
        <Col span={24}>
          <img className="sync" src={sync} alt="" onClick={syncData} />
          &nbsp;&nbsp;
          <img className="logout" src={logoutIcon} onClick={handleLogout} alt="" />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
