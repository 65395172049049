import React, { useState } from "react";
import { Row, Col, Button, Input, Card, Modal } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import Backspace from "../../assets/images/backspac.png";
import db from "../../database";
import "react-aspect-ratio/aspect-ratio.css";

const AddProduct = (props) => {
  const [dPadKey, setDpadKey] = useState("");
  const handleProductIdByDpad = (value) => {
    if (dPadKey === "" && value === "x") {
      setDpadKey("");
    } else if (value === "x") {
      setDpadKey((prevDpadKey) => `${prevDpadKey.toString().substring(0, prevDpadKey.toString().length - 1)}`);
    } else {
      setDpadKey((prevDPadKey) => `${prevDPadKey}${value}`);
    }
  };

  const dPadKeyInput = (event) => {
    setDpadKey(event.target.value);
  };

  const [displayBatchSelection, setDisplayBatchSelection] = useState(false);
  const [batchSetAvailable, setBatchSetAvailable] = useState([]);

  const getProductByDpadKey = (type) => {
    if (dPadKey !== "" && dPadKey !== "." && dPadKey !== undefined) {
      const validation = RegExp("^.{1,128}$");
      if (validation.test(dPadKey)) {
        db.products
          .where(type)
          .equalsIgnoreCase(dPadKey)
          .toArray()
          .then((product) => {
            if (product.length > 0) {
              const obj = product[0];
              if (obj.batchedProduct === "Y") {
                if (obj.mBatch.length === 1) {
                  addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
                } else {
                  // let upcKeys = 0;
                  const productSet = [];
                  for (let i = 0; i < obj.mBatch.length; i += 1) {
                    const batchItem = obj.mBatch[i];
                    const productDefined = {
                      batchno: batchItem.batchno,
                      description: obj.description,
                      discount: 0,
                      discountName: "",
                      imageurl: obj.imageurl,
                      isDecimal: obj.isDecimal,
                      isManualQty: obj.isManualQty,
                      isPromoApplicable: obj.isPromoApplicable,
                      isReturn: false,
                      mBatchId: batchItem.mBatchId,
                      mPricingruleId: null,
                      name: obj.name,
                      nettotal: 0,
                      primaryOrderLine: null,
                      productId: obj.mProductId,
                      realPrice: batchItem.price,
                      returnQty: null,
                      salePrice: batchItem.price,
                      stock: obj.onhandQty,
                      tax: obj.cTaxId,
                      taxAmount: 0,
                      taxRate: obj.taxRate,
                      uom: obj.csUomId,
                      uom_name: obj.uomName,
                      upc: batchItem.upc,
                      value: obj.value,
                      weight: 0,
                      shortDescription: obj.shortDescription,
                      hsncode: obj.hsncode,
                    };
                    //if (batchItem.upc.toLowerCase() === dPadKey.toLowerCase()) upcKeys += 1;
                    productSet.push(productDefined);
                  }
                  setBatchSetAvailable([...productSet]);
                  setDisplayBatchSelection(true);
                }
              } else {
                addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
              }
            } else {
              console.warn("No Products Found:", type, dPadKey);
            }
          });
      } else {
        console.warn("Invalid Input:", dPadKey);
      }
    } else {
      console.warn("Invalid Input:", dPadKey);
    }
  };

  const addDefinedProduct = (productObj, upc, batchno, mBatchId, price) => {
    const productDefined = {
      batchno: batchno,
      description: productObj.description,
      discount: 0,
      discountName: "",
      imageurl: productObj.imageurl,
      isDecimal: productObj.isDecimal,
      isManualQty: productObj.isManualQty,
      isPromoApplicable: productObj.isPromoApplicable,
      isReturn: false,
      mBatchId: mBatchId,
      mPricingruleId: null,
      name: productObj.name,
      nettotal: 0,
      primaryOrderLine: null,
      productId: productObj.mProductId,
      realPrice: price,
      returnQty: null,
      salePrice: price,
      stock: productObj.onhandQty,
      tax: productObj.cTaxId,
      taxAmount: 0,
      taxRate: productObj.taxRate,
      uom: productObj.csUomId,
      uom_name: productObj.uomName,
      upc: upc,
      value: productObj.value,
      weight: 0,
      shortDescription: productObj.shortDescription,
      hsncode: productObj.hsncode,
    };
    props.checkIsManualWeight(productDefined);
  };

  const selectProductToCart = (data) => {
    props.checkIsManualWeight(data);
    setDisplayBatchSelection(false);
  };

  return (
    <div>
      <Row>
        <Col span={4} />
        <Col span={16}>
          <Input size="large" allowClear={false} value={dPadKey} onChange={dPadKeyInput} className="addProductInput" />
        </Col>
        <Col span={4} />
      </Row>
      <div className="wrapper">
        <div className="item item2">
          <button onClick={() => handleProductIdByDpad("1")} className="dialer-btn">
            1
          </button>
        </div>
        <div className="item item3">
          <button onClick={() => handleProductIdByDpad("2")} className="dialer-btn">
            2
          </button>
        </div>
        <div className="item item4">
          <button onClick={() => handleProductIdByDpad("3")} className="dialer-btn">
            3
          </button>
        </div>
        <div className="item item9">
          <button onClick={() => getProductByDpadKey("upcIndex")} className="addByUPCbtn">
            <div style={{ display: "inline-grid" }}>
              <span>ADD</span>
              <span style={{ fontSize: "16px", opacity: 0.8 }}>By UPC</span>
            </div>
          </button>
        </div>
        <div className="item item6">
          <button onClick={() => handleProductIdByDpad("4")} className="dialer-btn">
            4
          </button>
        </div>
        <div className="item item7">
          <button onClick={() => handleProductIdByDpad("5")} className="dialer-btn">
            5
          </button>
        </div>
        <div className="item item8">
          <button onClick={() => handleProductIdByDpad("6")} className="dialer-btn">
            6
          </button>
        </div>
        <div className="item item5">
          <button className="addBySKUbtn" onClick={() => getProductByDpadKey("value")}>
            <div style={{ display: "inline-grid" }}>
              <span>ADD</span>
              <span style={{ fontSize: "16px", opacity: 0.8 }}>By SKU</span>
            </div>
          </button>
        </div>
        <div className="item item10">
          <button onClick={() => handleProductIdByDpad("7")} className="dialer-btn">
            7
          </button>
        </div>
        <div className="item item11">
          <button onClick={() => handleProductIdByDpad("8")} className="dialer-btn">
            8
          </button>
        </div>
        <div className="item item12">
          <button onClick={() => handleProductIdByDpad("9")} className="dialer-btn">
            9
          </button>
        </div>
        <div className="item item13">
          <button className="dialer-btn"></button>
        </div>
        <div className="item item14">
          <button onClick={() => handleProductIdByDpad(".")} className="dialer-btn">
            .
          </button>
        </div>
        <div className="item item15">
          <button onClick={() => handleProductIdByDpad("0")} className="dialer-btn">
            0
          </button>
        </div>
        <div className="item item16">
          <button onClick={() => handleProductIdByDpad("x")} className="dialer-btn">
            <img src={Backspace} alt="back space" width={30} height={25} />
          </button>
        </div>
        <div className="item item17">
          <button className="dialer-btn"></button>
        </div>
      </div>
      <div style={{ bottom: 0 }}>
        <Row>
          <Col span={12}>
            <Button size="large" className="add-product-save" onClick={() => props.parkBill()}>
              SAVE
            </Button>
          </Col>
          <Col span={12}>
            <Button size="large" className="add-product-pay" onClick={() => props.openPaymentModal()}>
              PAY
            </Button>
          </Col>
        </Row>
      </div>
      {/* BATCH SELECTION MODAL START*/}
      <Modal visible={displayBatchSelection} closable={false} footer={null} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
        <Row>
          <Col span={24}>
            <p style={{ textAlign: "center" }} className="parkedBillsHistoryModalTitle">
              Product Batch
            </p>
          </Col>
        </Row>
        <Row>
          <Card className="cardShadow card-radius orderHistoryCard" bodyStyle={{ padding: 10 }}>
            <Scrollbars autoHide style={{ minHeight: "53vh" }}>
              {batchSetAvailable.map((item) => (
                <Card className="cardShadow card-radius oderHistoryDetailsCard" bodyStyle={{ padding: 10 }} onClick={() => selectProductToCart(item)}>
                  <Row>
                    <Col span={12}>
                      <p className="orderDate">Batch</p>
                    </Col>
                    <Col span={12}>
                      <p className="orderCustomerName">Price</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p className="orderNum">{item.batchno}</p>
                    </Col>
                    <Col span={12}>
                      <p className="orderStatus">&#8377; {parseFloat(item.salePrice).toFixed(2)}</p>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Scrollbars>
          </Card>
        </Row>
      </Modal>
      {/* BATCH SELECTION MODAL END*/}
    </div>
  );
};

export default AddProduct;
