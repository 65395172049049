import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form, Input, Button, Spin, Modal } from "antd";
import { serverTokenUrl, serverUrl } from "../../constants/config";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../lib/auth";
import Axios from "axios";
import AspectRatio from "react-aspect-ratio";
import db from "../../database";
import loginBackground from "../../assets/images/backgroundImage.png";
import logoIcon from "../../assets/images/pos-logo-bordered.png";
import "react-aspect-ratio/aspect-ratio.css";
import "antd/dist/antd.css";
import "../style.css";

const Login = () => {
  const [till, setTill] = useState("");
  const [loading, setLoading] = useState({ status: false, message: "" });
  const { setAuthTokens } = useAuth();
  const history = useHistory();
  const qs = require("querystring");

  useEffect(() => {
    const tillRegistrationData = async () => {
      const tillRegistrations = await db.tillRegistrations.toArray();
      if (tillRegistrations.length > 0) {
        setTill(tillRegistrations[0].searchKey);
      } else {
        history.push("/registration");
      }
    };
    tillRegistrationData();
  }, [history]);

  const onLogin = (values) => {
    setLoading({ status: true, message: "" });
    console.log("Login:", values);
    const username = values.username.trim();
    const password = values.password.trim();

    const requestBody = {
      grant_type: "password",
      username,
      password,
    };
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=",
      },
    };

    Axios.post(serverTokenUrl, qs.stringify(requestBody), config).then((resTokenData) => {
      const paramsInput = {
        query: `query {
          tillData(user: "${username}") {
            tillAccess {
              cwrTillaccessId
              csClientId
              csUserId
              cwrTill {
                cwrTillID
                searchKey
                till
                description
                nextAssignedNumber
                prefix
                suffix
                loyaltyProgram
                accessController
                posType
                printTemplate {
                  cwrPrinttemplateId
                  name
                  htmlcode
                  htmlcode2
                }
                tillCloseTemplate{
                  cwrPrinttemplateId
                  name
                  htmlcode
                  htmlcode2
                }
              }
              csBunit {
                csBunitId
                name
                value
                cwrSCustomerId
                cwrCsDoctypeId
                cwrPcatalogueId
                cwrSpricelistId
                pCatalogueSaleType {
                  cwrPcatalogueSaletypeId
                  cwrSaletypeId
                  isPromoApplicable
                }
                b2cCustomer {
                  b2cCustomerId
                  name
                  mobileNo
                  sCustomerId
                }
                paymentMethodList {
                  cWRPaymentMethodID
                  finPaymentmethodId
                  finFinancialAccountId
                  name
                  integratedPayment
                  isloyalty
                  paymentProvider
                }
                mWarehouse {
                  mWarehouseID
                  name
                }
                customerAddress {
                  sCustomerAddressID
                  line1
                  line2
                  line3
                  fulladdress
                  phone
                  city
                  postalcode
                  csCountry {
                    csCountryID
                    name
                  }
                  csRegion {
                    csRegionID
                    name
                  }
                }
                locations{
                  fulladdress
                  phone
                  contactPerson
                }
              }
            }
            status
            message
          }
        }`,
      };
      Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${resTokenData.data.token_type} ${resTokenData.data.access_token}`,
        },
      })
        .then((resData) => {
          let tillData = resData.data.data.tillData;
          if (tillData.status === "200") {
            if (tillData !== null && tillData !== undefined) {
              const tillAccessIndex = tillData.tillAccess.findIndex((accessTill) => accessTill.cwrTill.till === till);
              if (tillAccessIndex >= 0) {
                tillData.tillAccess = tillData.tillAccess[tillAccessIndex];
                setAuthTokens(resTokenData.data);
                localStorage.setItem("username", username);
                localStorage.setItem("tillData", JSON.stringify(tillData));
                setLoading({ status: false, message: "" });
                db.productCategories.toArray().then((productCategories) => {
                  if (productCategories.length > 0) {
                    history.push("/dashboard");
                  } else {
                    history.push("/sync");
                  }
                });
              } else {
                setLoading({ status: false, message: "" });
                errorMessage("Login Failed", "User not configured for this Till");
              }
            } else {
              setLoading({ status: false, message: "" });
              errorMessage("Login Failed", "No Till Access Found");
            }
          } else {
            setLoading({ status: false, message: "" });
            errorMessage("Login Failed", "Till Access Failed");
          }
        })
        .catch((error) => {
          setLoading({ status: false, message: "" });
          errorMessage("Login Failed", "Invalid Username or Password");
          console.log(error);
        });
    });
  };

  const onLoginInvalid = (error) => {
    console.log("Login failed:", error);
  };

  const errorMessage = (modalTitle, modalContent) => {
    Modal.error({
      title: modalTitle,
      content: modalContent,
    });
  };

  var sectionStyle = {
    width: "100%",
    backgroundImage: `url(${loginBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: "100%",
  };

  const antIcon = <LoadingOutlined className="spinLoader" spin />;

  return (
    <AspectRatio ratio="19/6">
      <div style={sectionStyle}>
        <Card className="login-card">
          <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
            <img className="loginLogo" src={logoIcon} alt="" />
            <Form onFinish={onLogin} onFinishFailed={onLoginInvalid}>
              <Form.Item>
                <Row>
                  <Col span={8}>
                    <label className="login-label">Till ID</label>
                  </Col>
                  <Col span={16}>
                    <label className="login-label">{till}</label>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="username" rules={[{ required: true, message: "Please input your Username!" }]}>
                <Row gutter="5">
                  <Col span={8}>
                    <label>Username</label>
                  </Col>
                  <Col span={16}>
                    <Input className="login-input" placeholder="Username" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: "Please input your password!" }]}>
                <Row gutter="5">
                  <Col span={8}>
                    <label>Password</label>
                  </Col>
                  <Col span={16}>
                    <Input.Password className="login-input" placeholder="Password" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <br />
                <Row gutter={[5, 0]}>
                  <Col span={12} />
                  <Col span={12}>
                    <Button size="large" className="btnCommon" htmlType="submit">
                      Login
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <br />
        <div className="copyright-main-div">
          <label className="copyright">&emsp;&emsp;Copyright © {new Date().getFullYear()} CW Solutions Pvt Ltd | All rights reserved</label>
          <br />
          <label className="copyright">&emsp;&emsp;www.cw.solutions</label>
        </div>
      </div>
    </AspectRatio>
  );
};

export default Login;
