import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Card, Input, Divider, Button } from "antd";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { clock } from "../../utility/clock";
import { tillClosingPrint } from "../../lib/printer/tillCloseReceipt";
import logoIcon from "../../assets/images/pos-logo-bordered.png";
import db from "../../database";
import "../style.css";

const CloseTillAll = () => {
  const tillSession = JSON.parse(localStorage.getItem("tillSession"));
  const [allExpectedAmount, setAllExpectedAmount] = useState(0);
  const [allTransactionCount, setAllTransactionCount] = useState(0);
  const [paymentsList, setPaymentsList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);

  useEffect(() => {
    const tillSessionPayments = tillSession.allPaymentsData;
    let totalAmountAllPayments = 0;
    let transactionCount = 0;
    let total = 0;
    tillSessionPayments.map((pay) => {
      totalAmountAllPayments += pay.expectedAmount;
      transactionCount += pay.transactionCount;
      total += parseFloat(pay.amount);
      return null;
    });
    setAllExpectedAmount(totalAmountAllPayments);
    setAllTransactionCount(transactionCount);
    setTotalAmount(total);
    setTotalOrdersCount(tillSession.totalOrdersCount);
    setPaymentsList([...tillSessionPayments]);
  }, []); // eslint-disable-line

  const history = useHistory();

  const currentDate = new Date().toLocaleDateString();

  const [displayClock, setDisplayClock] = useState("");
  useEffect(() => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const confirmBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);

  const excuteCloseTillAll = () => {
    confirmBtnRef.current.disabled = true;
    cancelBtnRef.current.disabled = true;
    const updatedPaymentsList = paymentsList;
    const closingSession = tillSession;
    closingSession.tillStatus = "closed";
    closingSession.totalClosingExpectedAmount = allExpectedAmount;
    closingSession.totalClosingTransactions = allTransactionCount;
    closingSession.totalClosingAmount = totalAmount;
    closingSession.allPaymentsData = updatedPaymentsList;
    closingSession.isSynced = 0;

    db.tillEvents.add(closingSession).then(() => {
      //console.log(closingSession);
      tillClosingPrint(closingSession);
      localStorage.removeItem("tillSession");
      confirmBtnRef.current.disabled = false;
      cancelBtnRef.current.disabled = false;
      history.push("/dashboard");
    });
  };

  const onFieldInput = (e) => {
    const { name, value } = e.target;
    const updatedPaymentsList = paymentsList;
    const paymentIndex = updatedPaymentsList.findIndex((pi) => pi.name === name);
    updatedPaymentsList[paymentIndex].amount = value;
    const diff = parseFloat(value) - parseFloat(updatedPaymentsList[paymentIndex].expectedAmount);
    updatedPaymentsList[paymentIndex].difference = isNaN(diff) ? 0 : diff;
    setPaymentsList([...updatedPaymentsList]);
    let totalAmountVal = 0;
    updatedPaymentsList.map((p) => {
      return (totalAmountVal += isNaN(parseFloat(p.amount)) ? 0 : parseFloat(p.amount));
    });
    setTotalAmount(totalAmountVal);
  };

  const onFieldOut = (e) => {
    if (e.target.value === "") {
      const { name } = e.target;
      const value = 0;
      const updatedPaymentsList = paymentsList;
      const paymentIndex = updatedPaymentsList.findIndex((pi) => pi.name === name);
      updatedPaymentsList[paymentIndex].amount = value;
      const diff = parseFloat(value) - parseFloat(updatedPaymentsList[paymentIndex].expectedAmount);
      updatedPaymentsList[paymentIndex].difference = isNaN(diff) ? 0 : diff;
      setPaymentsList([...updatedPaymentsList]);
      let totalAmountVal = 0;
      updatedPaymentsList.map((p) => {
        return (totalAmountVal += isNaN(parseFloat(p.amount)) ? 0 : parseFloat(p.amount));
      });
      setTotalAmount(totalAmountVal);
    }
  };

  return (
    <div className="main-container">
      <Card className="card-shadow" bodyStyle={{ padding: 8 }}>
        <Row>
          <Col>
            <img className="logo-icon" src={logoIcon} alt="" />
          </Col>
        </Row>
      </Card>
      <div className="main-card-container">
        <Row gutter={10}>
          <Col span={18}>
            <Card key="z" className="card-shadow main-card-first" bodyStyle={{ padding: 10 }}>
              <Row>
                <Col span={5} className="col-align">
                  <h4 style={{ textAlign: "center" }}>
                    <strong>Payment Name</strong>
                  </h4>
                </Col>
                <Col span={4} className="col-align">
                  <h4 style={{ textAlign: "center" }}>
                    <strong>Transactions</strong>
                  </h4>
                </Col>
                <Col span={5} className="col-align">
                  <h4 style={{ textAlign: "center" }}>
                    <strong>Expected Amount</strong>
                  </h4>
                </Col>
                <Col span={5} className="col-align">
                  <h4 style={{ textAlign: "center" }}>
                    <strong>Actual Amount</strong>
                  </h4>
                </Col>
                <Col span={5} className="col-align">
                  <h4 style={{ textAlign: "center" }}>
                    <strong>Difference</strong>
                  </h4>
                </Col>
              </Row>
              {paymentsList.map((payment, index) => (
                <Card key={`${index}`} className="card-shadow" style={{ marginBottom: "10px" }} bodyStyle={{ padding: 6 }}>
                  <Row>
                    <Col span={5} className="col-align">
                      <h4 style={{ textAlign: "center" }}>{payment.name}</h4>
                    </Col>
                    <Col span={4} className="col-align">
                      <h4 style={{ textAlign: "center" }}>{payment.transactionCount}</h4>
                    </Col>
                    <Col span={5} className="col-align">
                      <h4 style={{ textAlign: "center" }}>{parseFloat(payment.expectedAmount).toFixed(2)}</h4>
                    </Col>
                    <Col span={5} className="col-align">
                      <Input /* className="currency-count" */ name={`${payment.name}`} value={payment.amount} onChange={onFieldInput} onBlur={onFieldOut} readOnly={payment.name.toLowerCase() === "cash" ? true : false} />
                    </Col>
                    <Col span={5} className="col-align">
                      <h4 style={{ textAlign: "center" }}>{parseFloat(payment.difference).toFixed(2)}</h4>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Card>
          </Col>
          <Col span={6}>
            <Link to="/close-till">
              <Button ref={cancelBtnRef} className="btn-cancel">
                Back
              </Button>
            </Link>
            <Button ref={confirmBtnRef} className="btn-confirm" onClick={excuteCloseTillAll}>
              Close Till
            </Button>
            <p style={{ marginBottom: "0.5rem" }} />
            <Card className="card-shadow main-card-second-open-till">
              <strong>CW</strong>
              <Divider style={{ margin: "5px 0" }} />
              <span>{currentDate}</span>
              <span style={{ float: "right" }}>{displayClock}</span>
              <br />
              <br />
              <Form>
                <Form.Item>
                  <strong>Payment Transactions :</strong> <span style={{ float: "right" }}>{allTransactionCount}</span>
                </Form.Item>
                <Form.Item>
                  <strong>Retail Transactions :</strong> <span style={{ float: "right" }}>{totalOrdersCount}</span>
                </Form.Item>
                <Form.Item>
                  <strong>Expected Amount</strong>
                  <Input type="number" value={`${parseFloat(allExpectedAmount).toFixed(2)}`} readOnly={true} />
                </Form.Item>
                <Form.Item>
                  <strong>Total Amount</strong>
                  <Input value={totalAmount} readOnly={true} />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CloseTillAll;
