import React, { useState, useEffect } from "react";
import { Spin, Card, Row, Col } from "antd";
import { LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { serverUrl } from "../../constants/config";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
import logoIcon from "../../assets/images/pos-logo-bordered.png";
import db from "../../database";
import "../style.css";

const Sync = () => {
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [loadingProductCategories, setLoadingProductCategories] = useState(true);
  const [loadingProductPricingRules, setLoadingProductPricingRules] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const limit = 5000;
    let from = 0;

    const processSync = async () => {
      try {
        await deletePreviousData();
        await syncProductCategories();
        await syncPricingRules();
        await syncPosSaleTypes();
        await syncProductsUom();
        await syncProducts();
      } catch (error) {
        console.log(error);
      }
    };

    const deletePreviousData = () => {
      return new Promise(async (deletionSuccess, deletionFailure) => {
        try {
          await db.productCategories.clear();
          await db.products.clear();
          await db.pricingRules.clear();
          await db.posSaletypes.clear();
          await db.productUom.clear();
          deletionSuccess();
        } catch (error) {
          deletionFailure(error);
        }
      });
    };

    const syncProductsUom = () => {
      return new Promise(async (uomSyncSuccess, uomSyncFailure) => {
        try {
          const paramsInput = {
            query: `query{
              getUom{
                csUomId
                costingprecision
                description
                ediCode
                name
                stdprecision
                symbol
              }
            }`,
          };
          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: paramsInput,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${tokens.token_type} ${tokens.access_token}`,
            },
          });
          const { getUom } = response.data.data;
          if (response.status === 200 && getUom.length > 0) {
            const lastId = await db.productUom.bulkAdd(getUom);
            console.log("POS Uom: ", " Synced");
            uomSyncSuccess(lastId);
          } else {
            uomSyncSuccess();
            console.log("POS Uom: ", getUom);
          }
        } catch (error) {
          console.log("POS Uom: ", " Sync Failed");
          uomSyncFailure(error);
        }
      });
    };

    const syncPosSaleTypes = () => {
      return new Promise(async (posSaleTypeSyncSuccess, posSaleTypeSyncFailure) => {
        try {
          const paramsInput = {
            query: `query{
              getPosSaletype(catalogueId:"${tillData.tillAccess.csBunit.cwrPcatalogueId}"){
                cwrPcatalogueSaletypeId
                cSClientID
                cSBunitID
                created
                createdby
                updated
                updatedby
                isactive
                isPromoApplicable
                cwrPCatalogueId
                cwrSaletype{
                  cwrSaletypeId
                  value
                  name
                  isdefault
                }
              }
            }`,
          };
          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: paramsInput,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${tokens.token_type} ${tokens.access_token}`,
            },
          });
          const { getPosSaletype } = response.data.data;
          if (response.status === 200 && getPosSaletype.length > 0) {
            const lastId = await db.posSaletypes.bulkAdd(getPosSaletype);
            console.log("POS Sale Types: ", " Synced");
            posSaleTypeSyncSuccess(lastId);
          } else {
            posSaleTypeSyncSuccess();
          }
        } catch (error) {
          console.log("POS Sale Types: ", " Sync Failed");
          posSaleTypeSyncFailure(error);
        }
      });
    };

    const syncProductCategories = () => {
      return new Promise(async (productCategorySyncSuccess, productCategorySyncFailure) => {
        try {
          const paramsInput = {
            query: `query {
            productCategory(bunit:"${tillData.tillAccess.csBunit.csBunitId}"){
            mProductCategoryId
            value
            name
            description
            imageurl
            }
          }`,
          };
          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: paramsInput,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${tokens.token_type} ${tokens.access_token}`,
            },
          });
          const { productCategory } = response.data.data;
          if (response.status === 200 && productCategory.length > 0) {
            const lastId = await db.productCategories.bulkAdd(productCategory);
            setLoadingProductCategories(false);
            console.log("Product Category: ", " Synced");
            productCategorySyncSuccess(lastId);
          } else {
            productCategorySyncSuccess();
          }
        } catch (error) {
          setLoadingProductCategories(false);
          console.log("Product Category: ", " Sync Failed");
          productCategorySyncFailure(error);
        }
      });
    };

    const syncPricingRules = () => {
      return new Promise(async (pricingRulesSyncSuccess, pricingRulesSyncFailure) => {
        try {
          const paramsInput = {
            query: `query{
              getPricingRules(catalogueId:"${tillData.tillAccess.csBunit.cwrPcatalogueId}"){
                mPricingrulesId
                csClientId
                csBunitId
                created
                createdBy
                updated
                updatedBy
                type
                name
                printedName
                description
                startDate
                endDate
                nextRule
                percentageDiscount
                amountDiscount
                minimumQty
                maximumQty
                xQty
                yQty
                billAmount
                maxBillAmount
                cwrSaletypeId
                fixedUnitPrice
                timeSpecific
                starttime
                endtime
                monday
                tuesday
                wednesday
                thursday
                friday
                saturday
                sunday
                status
                mPricingXProducts{
                  mPricingXProductId
                  line
                  mProductId
                  mBatchId
                  isFree
                }
                mPricingYProducts{
                  mPricingYProductId
                  line
                  mProductId
                }
                
                mPricingCcategories{
                  mPricingCcategoryId
                  line
                  sCustomerCategoryId
                }
              }
              
            }`,
          };
          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: paramsInput,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${tokens.token_type} ${tokens.access_token}`,
            },
          });
          const { getPricingRules } = response.data.data;
          if (response.status === 200 && getPricingRules.length > 0) {
            const lastId = await db.pricingRules.bulkAdd(getPricingRules);
            setLoadingProductPricingRules(false);
            console.log("Product Pricing Rules: ", " Synced");
            pricingRulesSyncSuccess(lastId);
          } else {
            setLoadingProductPricingRules(false);
            console.log("Product Pricing Rules: ", getPricingRules);
            pricingRulesSyncSuccess();
          }
        } catch (error) {
          setLoadingProductPricingRules(false);
          console.log("Product Pricing Rules: ", " Sync Failed");
          pricingRulesSyncFailure(error);
        }
      });
    };

    const syncProducts = () => {
      return new Promise(async (productSyncSuccess, productSyncFailure) => {
        try {
          const paramsInput = {
            query: `query{
                getProducts1(bUnit:"${tillData.tillAccess.csBunit.csBunitId}" from:${from} limit:${limit} ){
                mProductId
                csClientId
                csBunitId
                created
                createdby
                updated
                isactive
                updatedby
                sunitprice
                slistprice
                onhandQty
                description
                isVirtual
                isBestSeller
                cTaxId
                taxRate
                isPromoApplicable
                mProduct{
                  value
                  name
                  csTaxcategoryId
                  mProductCategoryId
                  csUomId
                  uomName
                  upc
                  batchedProduct
                  isManualQty
                  isDecimal
                  imageurl
                  shortDescription
                  hsncode
                  taxCategory{
                    name
                  }
                  mBatch{
                    mBatchId
                    batchno
                    upc
                    price
                    startdate
                    enddate
                    life
                  }
                }
              }
            }`,
          };
          from += 5000;
          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: paramsInput,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${tokens.token_type} ${tokens.access_token}`,
            },
          });
          const { getProducts1 } = response.data.data;
          const products = getProducts1;
          const productsLength = products.length;
          if (productsLength > 0) {
            const productsData = [];
            for (let i = 0; i < productsLength; i += 1) {
              const upcIndex = [];
              const batchIndex = [];
              if (products[i].mProduct.mBatch !== null) {
                for (let j = 0; j < products[i].mProduct.mBatch.length; j += 1) {
                  batchIndex.push(products[i].mProduct.mBatch[j].batchno);
                  upcIndex.push(products[i].mProduct.mBatch[j].upc);
                }
              }
              if (products[i].mProduct.upc !== null) {
                upcIndex.push(products[i].mProduct.upc);
              }
              const productDataObj = {
                mProductId: products[i].mProductId,
                csClientId: products[i].csClientId,
                csBunitId: products[i].csBunitId,
                created: products[i].created,
                createdby: products[i].createdby,
                updated: products[i].updated,
                isactive: products[i].isactive,
                updatedby: products[i].updatedby,
                sunitprice: products[i].sunitprice,
                slistprice: products[i].slistprice,
                onhandQty: products[i].onhandQty,
                description: products[i].description,
                shortDescription: products[i].mProduct.shortDescription,
                isVirtual: products[i].isVirtual,
                isBestSeller: products[i].isBestSeller,
                cTaxId: products[i].cTaxId,
                taxRate: products[i].taxRate,
                isPromoApplicable: products[i].isPromoApplicable,
                value: products[i].mProduct.value,
                name: products[i].mProduct.name,
                csTaxcategoryId: products[i].mProduct.csTaxcategoryId,
                mProductCategoryId: products[i].mProduct.mProductCategoryId,
                csUomId: products[i].mProduct.csUomId,
                uomName: products[i].mProduct.uomName,
                upc: products[i].mProduct.upc,
                batchedProduct: products[i].mProduct.batchedProduct,
                isManualQty: products[i].mProduct.isManualQty,
                isDecimal: products[i].mProduct.isDecimal,
                imageurl: products[i].mProduct.imageurl,
                taxCategory: products[i].mProduct.taxCategory.name,
                mBatch: products[i].mProduct.mBatch,
                hsncode: products[i].mProduct.hsncode,
                batchIndex: batchIndex,
                upcIndex: upcIndex,
              };
              productsData.push(productDataObj);
            }
            const lastId = await db.products.bulkAdd(productsData);
            console.log("Synced products count: ", lastId);
            syncProducts();
          } else {
            setLoadingProducts(false);
            console.log("Products: ", " Synced");
            productSyncSuccess();
            history.push("/dashboard");
          }
        } catch (error) {
          setLoadingProducts(false);
          console.log("Products : ", " Sync Failed");
          productSyncFailure(error);
        }
      });
    };
    processSync();
  }, [history]);

  const syncCat = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
  };

  const syncLabel = {
    xs: { span: 23 },
    sm: { span: 23 },
    md: { span: 23 },
    lg: { span: 23 },
    xl: { span: 23 },
    xxl: { span: 23 },
  };

  const syncIcon = {
    xs: { span: 1 },
    sm: { span: 1 },
    md: { span: 1 },
    lg: { span: 1 },
    xl: { span: 1 },
    xxl: { span: 1 },
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div className="mainDivStyl">
      <Row>
        <Col {...syncCat}>
          <Link to="/dashboard">
            <img className="logoIcon" src={logoIcon} alt="" />
          </Link>
        </Col>
      </Row>
      <div>
        <Row>
          <Col {...syncCat}>
            <Card>
              <Row>
                <Col {...syncLabel}>
                  <strong>Products</strong>
                </Col>
                <Col {...syncIcon}>
                  <Spin indicator={antIcon} spinning={loadingProducts}>
                    {loadingProducts === false ? <CheckCircleOutlined className="iconStyl" /> : ""}
                  </Spin>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="rowGap">
          <Col {...syncCat}>
            <Card>
              <Row>
                <Col {...syncLabel}>
                  <strong>Product Categories</strong>
                </Col>
                <Col {...syncIcon}>
                  <Spin indicator={antIcon} spinning={loadingProductCategories}>
                    {loadingProductCategories === false ? <CheckCircleOutlined className="iconStyl" /> : ""}
                  </Spin>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="rowGap">
          <Col {...syncCat}>
            <Card>
              <Row>
                <Col {...syncLabel}>
                  <strong>Warehouse</strong>
                </Col>
                <Col {...syncIcon}>
                  <Spin indicator={antIcon} spinning={loadingProductCategories}>
                    {loadingProductCategories === false ? <CheckCircleOutlined className="iconStyl" /> : ""}
                  </Spin>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="rowGap">
          <Col {...syncCat}>
            <Card>
              <Row>
                <Col {...syncLabel}>
                  <strong>Offers</strong>
                </Col>
                <Col {...syncIcon}>
                  <Spin indicator={antIcon} spinning={loadingProductPricingRules}>
                    {loadingProductPricingRules === false ? <CheckCircleOutlined className="iconStyl" /> : ""}
                  </Spin>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="rowGap">
          <Col {...syncCat}>
            <Card>
              <Row>
                <Col {...syncLabel}>
                  <strong>Price</strong>
                </Col>
                <Col {...syncIcon}>
                  <Spin indicator={antIcon} spinning={loadingProductCategories}>
                    {loadingProductCategories === false ? <CheckCircleOutlined className="iconStyl" /> : ""}
                  </Spin>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Sync;
